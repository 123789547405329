/* You can add global styles to this file, and also import other style files */
// @import "@angular/material/prebuilt-themes/indigo-pink.css";
@use '@angular/material' as mat;
@include mat.core();
// Material
@import './assets/styles/material/sleip-colors';
html,
body {
  height: 100%;
  // background: #131313;
  scroll-behavior: smooth;
  background: var(--green-700);
}
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

.icon {
  width: 24px;
  height: 24px;
  &.large {
    width: 64px;
    height: 64px;
    fill: #fff5d9;
  }

  &.grey {
    fill: #bcbcbc;
  }
  &.remove {
    fill: red;
  }
}

.rand {
  color: rgba(102, 204, 153, 1);
  color: rgba(255, 255, 255, 1);
  color: rgba(255, 240, 0, 1);
  color: rgba(244, 67, 54, 1);
}

body::-webkit-scrollbar {
  width: 0.3em;
}

body::-webkit-scrollbar-track {
  background: white;
}

body::-webkit-scrollbar-thumb {
}

// The "warn" palette is optional and defaults to red if not specified.
$sleip-light: mat.define-palette($sleip-palette);
$sleip-primary: mat.define-light-theme(
  (
    color: (
      primary: $sleip-light,
      accent: $sleip-light,
    ),
  )
);

// Emit theme-dependent styles for common features used across multiple components.
@include mat.core-theme($sleip-primary);
@include mat.all-component-themes($sleip-primary);
